import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridPaginationModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import useAdminCompanies from 'hooks/useAdminCompanies';
import { getCountryName } from 'utils/getCountryName';
import useAdminCompany from 'hooks/useAdminCompany';
import { useParams, useSearchParams } from 'react-router-dom';
import Merge from '../Merge';
import Toolbar from 'components/common/Toolbar';
import IconButton from 'components/common/IconButton';
import Close from 'components/icons/Close';
import { Container } from '@mui/material';
import Stack from 'components/common/Stack';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 120,
    valueFormatter: (params: GridCellParams) =>
      getCountryName(params.value as string),
  },
  {
    field: 'website',
    headerName: 'Website',
    width: 160,
    sortable: false,
  },
  {
    field: 'initial_source',
    headerName: 'Source',
    width: 160,
    sortable: false,
  },
];

export default function CompanyConsolidationTable() {
  const { companyId } = useParams();
  const { data: company } = useAdminCompany(companyId);

  const [search, setSearch] = React.useState(company?.name ?? '');
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: 10,
    });

  // Single row selection
  const [selectedRowId, setSelectedRowId] = React.useState<string | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();

  const source = searchParams.get('source') || '';
  const target = searchParams.get('target') || '';

  // Fetch data for the list of companies
  const { data, isFetching, refetch } = useAdminCompanies({
    search,
    page: paginationModel.page + 1, // convert 0-based to 1-based
    pageSize: paginationModel.pageSize,
  });

  const rows = data?.results?.filter((c: any) => c.id !== company?.id) || [];
  const totalCount = data?.count ?? 0;

  const onClose = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('source');
    newSearchParams.delete('target');
    setSearchParams(newSearchParams);
  };

  return (
    <Box>
      {/* Search field */}
      <TextField
        label="Search Companies"
        variant="outlined"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{ mb: 2 }}
      />

      {/* Data Grid with server-side pagination */}
      <Box sx={{ height: 500, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          rowCount={totalCount}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 20, 50]}
          pagination
          // --- SINGLE SELECTION SETUP ---
          checkboxSelection
          disableMultipleRowSelection // ensures single selection
          // The next lines manage the single selected row ID
          onRowSelectionModelChange={(newSelection) => {
            const selection = newSelection as string[];
            setSelectedRowId(selection.length ? selection[0] : null);
          }}
          rowSelectionModel={selectedRowId ? [selectedRowId] : []}
          // --- TOOLBAR ---
          slots={{
            toolbar: CustomToolbar as any,
          }}
          slotProps={{
            toolbar: {
              onMergeClick: () => {
                if (selectedRowId && companyId) {
                  setSearchParams({ source: selectedRowId, target: companyId });
                }
              },

              mergeDisabled: !selectedRowId,
            },
          }}
        />
      </Box>

      {/* Merge Dialog */}
      <Dialog
        open={!!source || !!target}
        onClose={onClose}
        transitionDuration={0}
        fullWidth
        maxWidth="sm"
        fullScreen
      >
        <Box height={0} overflow={'visible'}>
          <Container sx={{ paddingTop: 2 }}>
            <Stack direction="row">
              <div style={{ flex: 1 }} />
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Stack>
          </Container>
        </Box>

        <Merge
          onSuccess={() => {
            onClose();
            refetch();
          }}
        />
      </Dialog>
    </Box>
  );
}

// -------------------------------------------------------------------
// Below are helper components that live in the same file and are not exported.
// -------------------------------------------------------------------

/**
 * Custom toolbar with a quick filter and a Merge button.
 */
function CustomToolbar(props: {
  onMergeClick: () => void;
  mergeDisabled: boolean;
}) {
  const { onMergeClick, mergeDisabled } = props;
  return (
    <GridToolbarContainer>
      {/* MUI’s built-in quick filter */}
      {/* Merge button */}
      <Button
        color="primary"
        onClick={onMergeClick}
        disabled={mergeDisabled}
        sx={{ ml: 2 }}
      >
        Merge with company
      </Button>
    </GridToolbarContainer>
  );
}
