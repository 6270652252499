import Button from 'components/common/Button';
import { DownloadIcon } from 'components/icons/mui_icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { downloadFile } from 'utils/downloadFile';
import Loading from 'components/common/Loading';
import useUserCompanyExportFiles, {
  useUserCompanyExportFilesCreate,
} from 'hooks/useUserCompanyExportFiles';

function Component() {
  const { t } = useTranslation('common');
  const [clickedDownload, setClickDownload] = useState(false);

  const { mutateAsync, isLoading: isCreating } =
    useUserCompanyExportFilesCreate();

  const {
    isLoading: isLoadingExports,
    isFetching,
    refetch,
    data,
  } = useUserCompanyExportFiles({
    enabled: clickedDownload,
  });

  useEffect(() => {
    if (
      !isFetching &&
      !isCreating &&
      !isLoadingExports &&
      clickedDownload &&
      data &&
      data.results
    ) {
      if (data.results[0].status === 'COMPLETED') {
        // download file
        const regex = /\/([^\/]+\.xlsx)/;
        const match = data.results[0].file.match(regex);

        downloadFile(data.results[0].file, match[1] || 'Export.xlsx');
        setClickDownload(false);
      } else if (data.results[0].status === 'FAILED') {
        // something went wrong
        setClickDownload(false);
      }
    }
  }, [
    isLoadingExports,
    isFetching,
    isCreating,
    clickedDownload,
    data,
    setClickDownload,
  ]);

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      onClick={async () => {
        await mutateAsync();
        refetch();
        setClickDownload(true);
      }}
      disabled={clickedDownload || isCreating}
      startIcon={clickedDownload ? <Loading size={'18px'} /> : <DownloadIcon />}
    >
      {t('Download Supplier Data')}
    </Button>
  );
}

export default Component;
