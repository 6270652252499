import React from 'react';
// import SvgIcon from '@mui/material/SvgIcon';
import CloseIcon from '@mui/icons-material/Close';

// can swap later
const Close = (props: any) => (
  // <SvgIcon {...props}>
  //
  // </SvgIcon>
  <CloseIcon {...props} />
);

export default Close;
