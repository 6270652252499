import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useUserCompanyExportFiles({
  enabled,
  collectionId,
}: {
  enabled: boolean;
  collectionId?: string;
}) {
  const auth = useAuth();
  return useQuery({
    enabled: enabled,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchInterval: 1000,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['user-company-export-files', { collectionId }],
    queryFn: async () => {
      const res = await axios.get(`${api.url}/user/company/exports/`, {
        ...auth.requestConfig,
        params: collectionId ? { collection_id: collectionId } : {},
      });
      return res.data;
    },
  });
}

export function useUserCompanyExportFilesCreate(params?: {
  collectionId?: string;
}) {
  const auth = useAuth();
  return useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `${api.url}/user/company/exports/`,
        {
          collection_id: params?.collectionId,
        },
        auth.requestConfig
      );
      return res;
    },
  });
}
