import React, { useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import { useAdminCompanyPatch } from 'hooks/useAdminCompany';
import Avatar from 'components/common/Avatar';
import { getMediaUrlSrc } from 'utils/browser';
import CountrySelect from 'components/common/CountrySelect';
import SectorSelect from 'components/common/SectorSelect';
import Box from 'components/common/Box';

function sanitizeData(data: any) {
  const sanitized: any = {};
  for (const key in data) {
    // Assume that fields which are supposed to be strings should not be null.
    // You can adjust this logic if you have other types.
    sanitized[key] = data[key] === null ? '' : data[key];
  }
  return sanitized;
}

const InfoItem = ({ label, value }: { label?: string; value?: string }) => (
  <Grid item xs={12}>
    <Stack spacing={1} direction={'row'}>
      <Typography whiteSpace={'nowrap'} component={'div'} variant="caption">
        {label}
      </Typography>

      <Typography variant="body2" noWrap title={value}>
        {value}
      </Typography>
    </Stack>
  </Grid>
);

/**
 * Props for CompanyEditForm
 */
interface CompanyEditFormProps {
  data?: any;
  onClose?: () => void;
  onSuccess?: () => void;
  disabled?: boolean;
  vertical?: boolean;
}

/**
 * Form field definitions
 */
interface FormValues {
  id: string;
  slug: string;
  name: string;
  hubspot_id: string;
  last_hubspot_sync: string;
  created: string;
  modified: string;
  initial_source: string;
  profile_search_ranking: string;
  last_screened: string;
  customer_valid_crcs_export: string;
  latest_customer_response_export: string;
  supplier_valid_crcs_export: string;
  banner: string;
  employee_range: string;
  turnover: string;
  acquisition_type: string;

  // Basic fields
  verified: boolean;
  is_crc_visible: boolean;
  published: boolean;
  address: string;
  address2: string;
  zip_code: string;
  city: string;
  region: string;
  country: string;
  email: string;
  website: string;
  phone: string;
  currency: string;

  has_parent_company: boolean;
  has_subsidiaries: boolean;
  parent_company_name: string;
  reporting_company: string;
  lei_code: string;
  isin: string;
  sector: string;
  short_description: string;
  description: string;

  // Social
  facebook_handle: string;
  instagram_handle: string;
  linkedin_handle: string;
  twitter_handle: string;

  // For arrays/JSON, keep as string or you can parse as needed
  tcc_impact_categories: string;
  tcc_affiliations: string;
  sdgs: string;
  certifications: string;
  tcc_product_tags: string;
  tcc_nace_codes: string;
  tcc_impact_tags: string;
  tcc_eu_taxonomy_codes: string;

  // Logo handling
  logo: File | null;
}

/**
 * CompanyEditForm using react-hook-form + react-query
 */
export default function CompanyEditForm({
  data,
  onClose,
  vertical = false,
  onSuccess,
  disabled,
}: CompanyEditFormProps) {
  const { t } = useTranslation('common');
  const auth = useAuth();

  // Whether to show extended fields
  const [moreFields, setMoreFields] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const sanitizedData = data ? sanitizeData(data) : {};

  /**
   * Initialize react-hook-form
   * You can prefill defaultValues with an existing company's data if you like.
   */
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      id: 'NEW',
      slug: '',
      name: '',
      hubspot_id: '',
      last_hubspot_sync: '',
      created: '',
      modified: '',
      initial_source: '',
      profile_search_ranking: '',
      last_screened: '',
      customer_valid_crcs_export: '',
      latest_customer_response_export: '',
      supplier_valid_crcs_export: '',
      banner: '',
      employee_range: '',
      turnover: '',
      acquisition_type: '',

      verified: false,
      is_crc_visible: false,
      published: false,
      address: '',
      address2: '',
      zip_code: '',
      city: '',
      region: '',
      country: '',
      email: '',
      website: '',
      phone: '',
      currency: '',

      has_parent_company: false,
      has_subsidiaries: false,
      parent_company_name: '',
      reporting_company: '',
      lei_code: '',
      isin: '',
      sector: '',
      short_description: '',
      description: '',

      facebook_handle: '',
      instagram_handle: '',
      linkedin_handle: '',
      twitter_handle: '',

      tcc_impact_categories: '',
      tcc_affiliations: '',
      sdgs: '',
      certifications: '',
      tcc_product_tags: '',
      tcc_nace_codes: '',
      tcc_impact_tags: '',
      tcc_eu_taxonomy_codes: '',

      logo: '',
      ...sanitizedData,
    },
  });

  const {
    mutateAsync: patchCompany,
    isLoading,
    isError,
    error,
    reset: resetMutation,
  } = useAdminCompanyPatch(data.id);

  const onSubmit = async (formData: FormValues) => {
    const newData = new FormData();
    // Compare each key from the form data with the original data
    Object.keys(formData).forEach((key) => {
      // Note: This shallow comparison works for primitive values.
      if (sanitizedData[key] !== formData[key as keyof FormValues]) {
        if (key === 'cdp_summary') return;
        if (key === 'certifications') return;
        if (key === 'company_targets') return;
        if (key === 'initial_source') return;
        if (key === 'sdgs') return;
        if (key === 'tcc_affiliations') return;
        if (key === 'tcc_impact_categories') return;

        newData.set(key, formData[key as keyof FormValues] as any);
      }
    });
    await patchCompany({ data: newData });
    if (onSuccess) {
      onSuccess();
    }
  };

  // File change logic
  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const maxSize = 3140000; // ~3MB
    if (file.size > maxSize) {
      alert(t('Maximum file size is 3MB'));
      setValue('logo', null, { shouldDirty: true });
    } else {
      setValue('logo', file, { shouldDirty: true });
    }
  };

  // For convenience
  const logo = watch('logo');
  const isDisabled = disabled || isLoading;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={vertical ? 12 : 5}>
          <Grid container spacing={2}>
            {/* ID and Slug info */}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <InfoItem
                  label={t('Initial Source:')}
                  value={watch('initial_source')}
                />
                <InfoItem label="Slug:" value={watch('slug')} />
                <InfoItem label={t('Id:')} value={watch('id')} />
                <Button
                  variant="text"
                  onClick={() => setMoreInfo((prev) => !prev)}
                  sx={{ mt: 1 }}
                >
                  {moreInfo ? t('Less') : t('More')}
                </Button>

                {(moreInfo || !vertical) && (
                  <>
                    <InfoItem
                      label={t('Hubspot Id:')}
                      value={watch('hubspot_id')}
                    />
                    <InfoItem
                      label={t('Last Hubspot Sync:')}
                      value={watch('last_hubspot_sync')}
                    />
                    <InfoItem label={t('Created:')} value={watch('created')} />
                    <InfoItem
                      label={t('Modified:')}
                      value={watch('modified')}
                    />
                    <InfoItem
                      label={t('Profile Search Ranking:')}
                      value={watch('profile_search_ranking')}
                    />
                    <InfoItem
                      label={t('Last Screened:')}
                      value={watch('last_screened')}
                    />
                    <InfoItem
                      label={t('Customer Valid Crcs Export:')}
                      value={watch('customer_valid_crcs_export')}
                    />
                    <InfoItem
                      label={t('Latest Customer Response Export:')}
                      value={watch('latest_customer_response_export')}
                    />
                    <InfoItem
                      label={t('Supplier Valid Crcs Export:')}
                      value={watch('supplier_valid_crcs_export')}
                    />
                    <InfoItem label={t('Banner:')} value={watch('banner')} />
                    <InfoItem
                      label={t('Employee Range:')}
                      value={watch('employee_range')}
                    />
                    <InfoItem
                      label={t('Turnover:')}
                      value={watch('turnover')}
                    />
                    <InfoItem
                      label={t('Acquisition Type:')}
                      value={watch('acquisition_type')}
                    />
                  </>
                )}
              </Grid>
            </Grid>

            {/* Checkboxes */}
            <Grid item xs={12}>
              <Stack spacing={0}>
                <Controller
                  name="verified"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t('Is verified')}
                    />
                  )}
                />

                <Controller
                  name="is_crc_visible"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t('Is CPA visible')}
                    />
                  )}
                />

                <Controller
                  name="published"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t('Published')}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={vertical ? 12 : 7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                {t('Company Logo')}
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <Avatar src={getMediaUrlSrc(watch('logo'))} />
                <label htmlFor={`logo-id-${watch('id')}`}>
                  <input
                    hidden
                    id={`logo-id-${watch('id')}`}
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                  />
                  <Button
                    variant="outlined"
                    component="span"
                    disabled={isDisabled}
                    startIcon={<UploadIcon />}
                  >
                    {t('Choose file')}
                  </Button>
                </label>
                <Button
                  variant="outlined"
                  startIcon={<CloseIcon />}
                  disabled={isDisabled || !logo}
                  onClick={() => {
                    setValue('logo', null, { shouldDirty: true });
                  }}
                >
                  {t('Remove')}
                </Button>
              </Stack>
            </Grid>

            {/* Name */}
            <Grid item xs={12}>
              <TextField
                label={t('Company Name')}
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? t('Name is required') : ''}
                {...register('name', { required: true })}
              />
            </Grid>

            {/* Address fields */}
            <Grid item xs={12}>
              <TextField
                label={t('Address Line 1')}
                fullWidth
                {...register('address')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Address Line 2')}
                fullWidth
                {...register('address2')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Zip Code')}
                fullWidth
                {...register('zip_code')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label={t('City')} fullWidth {...register('city')} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Region')}
                fullWidth
                {...register('region')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Country')}
                disabled
                fullWidth
                {...register('country')}
              />
              <CountrySelect
                value={watch('country')}
                onChange={(value) =>
                  setValue('country', value, { shouldDirty: true })
                }
              />
            </Grid>
            {/* Contact fields */}
            <Grid item xs={12}>
              <TextField label={t('Email')} fullWidth {...register('email')} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Website')}
                fullWidth
                {...register('website')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label={t('Phone')} fullWidth {...register('phone')} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Currency')}
                fullWidth
                {...register('currency')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t('Parent Company Name')}
                fullWidth
                {...register('parent_company_name')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Reporting Company')}
                fullWidth
                {...register('reporting_company')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Company LEI code')}
                fullWidth
                {...register('lei_code')}
                helperText={t(
                  'Legal Entity Identifier (LEI) code for your company'
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField label={t('ISIN')} fullWidth {...register('isin')} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Sector')}
                fullWidth
                disabled
                {...register('sector')}
              />
              <SectorSelect
                value={watch('sector')}
                onChange={(value) =>
                  setValue('sector', value, { shouldDirty: true })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Short Description')}
                multiline
                rows={3}
                fullWidth
                {...register('short_description')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('Description')}
                multiline
                rows={6}
                fullWidth
                {...register('description')}
              />
            </Grid>

            {/* More fields toggle */}
            <Button variant="text" onClick={() => setMoreFields(!moreFields)}>
              {moreFields ? t('Less') : t('More')}
            </Button>
            {moreFields && (
              <>
                <Grid item xs={12}>
                  <Stack spacing={0}>
                    <Controller
                      name="has_parent_company"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value || false}
                            />
                          }
                          label={t('Has Parent Company')}
                        />
                      )}
                    />

                    <Controller
                      name="has_subsidiaries"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value || false}
                            />
                          }
                          label={t('Has Subsidiaries')}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Facebook Link')}
                    fullWidth
                    {...register('facebook_handle')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Instagram Link')}
                    fullWidth
                    {...register('instagram_handle')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Linked In Link')}
                    fullWidth
                    {...register('linkedin_handle')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('Twitter Link')}
                    fullWidth
                    {...register('twitter_handle')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Tcc impact categories (JSON)"
                    fullWidth
                    multiline
                    rows={3}
                    {...register('tcc_impact_categories')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Tcc affiliations (JSON)"
                    fullWidth
                    multiline
                    rows={3}
                    {...register('tcc_affiliations')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="SDGs (JSON)"
                    fullWidth
                    multiline
                    rows={3}
                    {...register('sdgs')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Certifications (JSON)"
                    fullWidth
                    multiline
                    rows={3}
                    {...register('certifications')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Product Tags (JSON)"
                    fullWidth
                    multiline
                    rows={3}
                    {...register('tcc_product_tags')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Nace codes (JSON)"
                    fullWidth
                    multiline
                    rows={3}
                    {...register('tcc_nace_codes')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Impact Tags (JSON)"
                    fullWidth
                    multiline
                    rows={3}
                    {...register('tcc_impact_tags')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="EU Taxonomy Codes (JSON)"
                    fullWidth
                    multiline
                    rows={3}
                    {...register('tcc_eu_taxonomy_codes')}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {isError && (
            <Grid item xs={12}>
              <Alert severity="error">
                {error && (error as any)?.response?.data
                  ? JSON.stringify((error as any)?.response?.data)
                  : t('There was a problem')}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isDisabled || !isDirty}
              >
                {t('Save')}
              </Button>
              <Button
                onClick={() => {
                  reset();
                  resetMutation();
                }}
              >
                {t('Reset')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
