import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import useAuth from 'hooks/useAuth';
import useUserCompany from 'hooks/useUserCompany';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';
import Stack from 'components/common/Stack';
import AppLoading from 'components/common/AppLoading';
import exampleData from './exampleData';
import Box from 'components/common/Box';
import CollectionOverviewList from './CollectionList';
import useUserCompanyCollections from 'hooks/useUserCompanyCollections';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/common/Button';
import Download from '@mui/icons-material/Download';
import Link from 'components/common/Link';
import colors from 'constants/colors';
import LinearProgress from 'components/common/LinearProgress';
import CollectionWidgetCountries from './CollectionWidgetCountries';
import { ChevronRight } from '@mui/icons-material';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import WidgetCard from './CollectionCard';
import CollectionsUpsell from '../CollectionsUpsell';

import { downloadFile } from 'utils/downloadFile';
import Loading from 'components/common/Loading';
import useUserCompanyExportFiles, {
  useUserCompanyExportFilesCreate,
} from 'hooks/useUserCompanyExportFiles';
import { DownloadIcon } from 'components/icons/mui_icons';

function DownloadCollectionData({
  collectionId,
  disabled,
}: {
  collectionId?: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation('common');
  const [clickedDownload, setClickDownload] = useState(false);

  const { mutateAsync, isLoading: isCreating } =
    useUserCompanyExportFilesCreate({ collectionId });

  const {
    isLoading: isLoadingExports,
    isFetching,
    refetch,
    data,
  } = useUserCompanyExportFiles({
    enabled: clickedDownload,
    collectionId,
  });

  useEffect(() => {
    if (
      !isFetching &&
      !isCreating &&
      !isLoadingExports &&
      clickedDownload &&
      data &&
      data.results
    ) {
      if (data.results[0].status === 'COMPLETED') {
        // download file
        const regex = /\/([^\/]+\.xlsx)/;
        const match = data.results[0].file.match(regex);

        downloadFile(data.results[0].file, match[1] || 'Export.xlsx');
        setClickDownload(false);
      } else if (data.results[0].status === 'FAILED') {
        // something went wrong
        setClickDownload(false);
      }
    }
  }, [
    isLoadingExports,
    isFetching,
    isCreating,
    clickedDownload,
    data,
    setClickDownload,
  ]);

  return (
    <Button
      type="button"
      onClick={async () => {
        await mutateAsync();
        refetch();
        setClickDownload(true);
      }}
      disabled={clickedDownload || isCreating || disabled}
      startIcon={clickedDownload ? <Loading size={'18px'} /> : <DownloadIcon />}
    >
      {t('Download Data')}
    </Button>
  );
}

function UserDashboardCollection() {
  const auth = useAuth();

  const { t } = useTranslation('common');
  const { userCompany } = useUserCompany();
  const navigate = useNavigate();

  // const { data: sectors } = useCompanySectors();
  //   <Stack
  //     direction={'row'}
  //     sx={{ flexWrap: 'wrap', gap: 1 }}
  //   >
  //     {uniqueSectors.map((sector: string) => (
  //       <Chip key={sector} label={sectors[sector]} />
  //     ))}
  //     {uniqueSectors.map((sector: string) => (
  //       <Chip key={sector} label={sectors[sector]} />
  //     ))}
  //     {uniqueSectors.map((sector: string) => (
  //       <Chip key={sector} label={sectors[sector]} />
  //     ))}
  //   </Stack>

  const { isLoading, data: collections, isError } = useUserCompanyCollections();

  const { collectionId } = useParams();

  const showExample = collectionId === 'example';

  const collectionNames = collections
    ? collections.map((d: any) => d.name)
    : [];
  const collectionIndex = collections?.findIndex(
    (d: any) => d.id === collectionId
  );
  const collection = !collections
    ? {}
    : collectionIndex > -1
    ? collections[collectionIndex]
    : collections[0];

  const { data: collectionOverview } = useUserCompanyCollectionOverview({
    collection_id: showExample ? undefined : collection?.id,
  });
  const collectionData = showExample
    ? exampleData
    : !!collectionOverview
    ? collectionOverview
    : [];
  const hasCollections = collectionNames.length > 0;

  const publicDataAvailability =
    collectionData.length > 0
      ? (collectionData.reduce(
          (sum: number, item: any) =>
            item.company_has_public_data ||
            item.reporting_company_has_public_data
              ? sum + 1
              : sum,
          0
        ) /
          collectionData.length) *
        100
      : undefined;

  const mostFrequentMaturityList = collectionData
    .map(
      (d: any) =>
        (d.reporting_company_public_data_screening_last_date &&
          d.reporting_company_maturity_level) ||
        (d.company_public_data_screening_last_date &&
          d.company_maturity_level) ||
        d.reporting_company_maturity_level_crc ||
        d.company_maturity_level_crc
    )
    .reduce(
      (acc: any, maturity: any) => (
        (acc[maturity] = (acc[maturity] || 0) + 1), acc
      ),
      {} as Record<string, number>
    );

  const mostFrequentMaturity =
    Object.keys(mostFrequentMaturityList).length > 0
      ? Object.entries(mostFrequentMaturityList).sort(
          (a: any, b: any) => b[1] - a[1]
        )[0][0]
      : '';

  const uniqueSectors = collectionData
    .map((item: any) => item.company_sector)
    .filter(
      (sector: any, index: any, array: any) =>
        sector && array.indexOf(sector) === index
    );

  const uniqueCountries = collectionData
    .map((item: any) => item.company_country)
    .filter(
      (country: any, index: any, array: any) =>
        country && array.indexOf(country) === index
    );

  return isLoading ? (
    <AppLoading />
  ) : isError ? (
    <Alert severity="error">{t('Something went wrong')}</Alert>
  ) : (
    <Stack spacing={5}>
      <Stack spacing={1}>
        <Stack spacing={1} direction={'row'} alignItems={'center'}>
          <Link
            underline="hover"
            color="inherit"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/dashboard/my-company/collections')}
          >
            {t('Collections')}
          </Link>

          <ChevronRight color="inherit" sx={{ opacity: 0.15 }} />
          <Typography variant="body1" noWrap sx={{ opacity: 0.4 }}>
            {showExample ? t('Example') : collection?.name}
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          spacing={1}
          alignItems={{ sm: 'flex-start', md: 'flex-end' }}
        >
          <Typography variant="h4">
            {showExample ? t('Example') : collection?.name}
          </Typography>
          <Box flex={1}></Box>
          {collection?.id && (
            <Box>
              <DownloadCollectionData
                disabled={showExample}
                collectionId={collection?.id}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <WidgetCard
              title={
                publicDataAvailability === undefined
                  ? '-'
                  : publicDataAvailability.toFixed(0) + '%'
              }
              caption={t('Data availability')}
              component={
                <LinearProgress
                  variant="determinate"
                  value={publicDataAvailability || 0}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <WidgetCard
              title={
                mostFrequentMaturity === 'GOOD'
                  ? t('Good')
                  : mostFrequentMaturity === 'FAIR'
                  ? t('Fair')
                  : t('Poor')
              }
              titleBadge={
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    backgroundColor:
                      mostFrequentMaturity === 'GOOD'
                        ? colors.primary500
                        : mostFrequentMaturity === 'FAIR'
                        ? colors.warning500
                        : colors.tertiary500,
                  }}
                />
              }
              caption={t('Overall maturity')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={3}>
            <WidgetCard
              title={uniqueSectors.length}
              caption={t('Sectors', {
                count: uniqueSectors.length,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} minWidth={'180px'}>
            <WidgetCard
              title={uniqueCountries.length}
              caption={t('Countries', { count: uniqueCountries.length })}
              component={
                <Box overflow={'visible'}>
                  <Box
                    maxWidth={'220px'}
                    maxHeight={'0px'}
                    mt={{ xl: -7, lg: -7, md: -7, sm: -8, xs: -7 }}
                    ml={4}
                  >
                    <CollectionWidgetCountries countries={uniqueCountries} />
                  </Box>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Stack spacing={1}>
        <Typography variant="h5">{t('Companies')}</Typography>
        <CollectionOverviewList
          hasCollections={hasCollections}
          collectionId={collection?.id}
          data={collectionData}
        />
        {showExample ? (
          <Box pt={1}>
            <CollectionsUpsell />
          </Box>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default observer(UserDashboardCollection);
