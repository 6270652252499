import axios from 'axios';

export const downloadFile = async (fileSrc: string, fileName: string) => {
  try {
    // Axios will handle the download for all file types, including images, as a blob
    const response = await axios.get(fileSrc, {
      responseType: 'blob', // Ensure the file is treated as binary data (blob)
    });

    // Get the content-type from the response and determine the file extension
    const contentType = response.headers['content-type'];
    const fileExtension = contentType.split('/')[1]; // Extract the file extension from the MIME type

    // Create a blob from the response
    const blob = new Blob([response.data], { type: contentType });
    const fileURL = URL.createObjectURL(blob);

    // Create an invisible anchor element
    const link = document.createElement('a');
    link.href = fileURL;

    // If fileName already ends with .xlsx (case-insensitive), use it as is.
    // Otherwise, append the extension from the response.
    const downloadName = /\.xlsx$/i.test(fileName)
      ? fileName
      : `${fileName}.${fileExtension}`;

    link.download = downloadName;
    document.body.appendChild(link);
    link.click(); // Trigger the download
    document.body.removeChild(link); // Clean up the DOM

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(fileURL);
  } catch (error) {
    console.error('Error downloading the file:', error);
    alert('Failed to download the file. Please try again.');
  }
};
