import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import api from 'constants/api';
import useAuth from 'hooks/useAuth';

import Form from './Form';
import { LinearProgress } from '@mui/material';
import colors from 'constants/colors';
import useAdminCompany from 'hooks/useAdminCompany';

export type Company = {
  id: string;
  name: string;
  country: string;
  slug: string;
};

const Component = ({
  id,
  onSuccess,
  vertical = false,
}: {
  id: string;
  onSuccess?: () => void;
  vertical?: boolean;
}) => {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const { isFetching, data, isError, isFetched, refetch, isRefetching } =
    useAdminCompany(id);

  const onFormSuccess = () => {
    refetch();
    if (onSuccess) {
      onSuccess();
    }
  };

  return isFetching ? (
    <LinearProgress color="primary" />
  ) : (
    <>
      <div style={{ display: 'block', color: colors.white }}>
        {isRefetching ? (
          <LinearProgress color="primary" />
        ) : (
          <LinearProgress color="inherit" variant="determinate" value={100} />
        )}
      </div>
      <Form
        vertical={vertical}
        data={data}
        disabled={isFetching}
        onSuccess={onFormSuccess}
      />
      {isError ? (
        <Alert severity="error">{t('There was a problem')}</Alert>
      ) : null}
    </>
  );
};

export default Component;
