import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
// hooks/useDebounce.ts
import { useEffect, useState } from 'react';

function useDebounce<T>(value: T, delay = 300): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

interface UseAdminCompaniesParams {
  search?: string;
  page?: number; // 1-based
  pageSize?: number;
}

export default function useAdminCompanies({
  search,
  page = 1,
  pageSize = 10,
}: UseAdminCompaniesParams) {
  const auth = useAuth();

  // Debounce the search term to throttle requests
  const debouncedSearch = useDebounce(search, 500);

  return useQuery({
    // The query key uses the debouncedSearch to avoid refetching on every keystroke
    queryKey: ['admin-companies', { search: debouncedSearch, page, pageSize }],
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0,
    staleTime: 0,
    queryFn: async () => {
      const params = new URLSearchParams();

      if (debouncedSearch) {
        params.set('search', debouncedSearch);
      }
      params.set('page', String(page)); // 1-based
      params.set('page_size', String(pageSize));

      const { data } = await axios.get(`${api.url}/admin/companies/`, {
        ...auth.requestConfig,
        params,
      });
      // Expect { count, results } shape
      return data;
    },
    keepPreviousData: true,
  });
}

interface UseAdminCompaniesInfiniteParams {
  search?: string;
  pageSize?: number;
  enabled?: boolean;
}

export function useAdminCompaniesInfinite({
  search,
  enabled = true,
  pageSize = 10,
}: UseAdminCompaniesInfiniteParams) {
  const auth = useAuth();
  // NEW: Debounce the search term to avoid excessive API calls.
  const debouncedSearch = useDebounce(search, 500);

  return useInfiniteQuery({
    enabled: enabled,
    // NEW: Use infinite query so that additional pages can be fetched.
    queryKey: [
      'admin-companies-infinite',
      { search: debouncedSearch, pageSize },
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const params = new URLSearchParams();
      if (debouncedSearch) {
        params.set('search', debouncedSearch);
      }
      // NEW: Use pageParam (default to 1) instead of a local page state.
      params.set('page', String(pageParam));
      params.set('page_size', String(pageSize));

      const { data } = await axios.get(`${api.url}/admin/companies/`, {
        ...auth.requestConfig,
        params,
      });
      // Expecting the API to return an object with { count, results }.
      return data;
    },
    // NEW: Determine the next page based on the total count.
    getNextPageParam: (lastPage, allPages) => {
      const totalFetched = allPages.reduce(
        (acc, page) => acc + page.results.length,
        0
      );
      if (totalFetched < lastPage.count) {
        return allPages.length + 1;
      }
      return undefined;
    },
    keepPreviousData: true,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
