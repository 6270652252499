import React from 'react';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';

function CompanyDataServicesList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { companyId } = useParams();
  const [page, setPage] = React.useState(0);
  const { data } = useAdminCompanyJobs({
    companyId,
    page: page + 1,
    refetchInterval: 100000,
  });
  const rowCount = data?.count || 0;

  const rows =
    data && data.results
      ? data.results.map((service: any) => ({
          id: service.id,
          type: service.type,
          status: service.status,
          created: service.created,
          modified: service.modified,
          inputs: service.inputs || [],
          outputs: service.outputs || [],
        }))
      : [];

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: t('Type'),
      flex: 0,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row?.status}
          style={{
            borderRadius: 4,
            fontSize: '12px',
            color: colors.neutral600,
            height: '24px',
            backgroundColor:
              params.row?.status === 'PENDING'
                ? colors.warning100
                : params.row?.status === 'COMPLETED'
                ? colors.primary100
                : colors.neutral200,
          }}
        />
      ),
    },
    {
      field: 'modified',
      headerName: t('Last updated'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        const longAgo = getDurationBetweenDates(
          params.row?.modified,
          new Date().toUTCString()
        );
        return (
          <Box
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            title={longAgo}
          >
            {longAgo}
          </Box>
        );
      },
    },
    {
      field: 'duration',
      headerName: t('Duration'),
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => {
        const length = getLengthOfTime(
          params.row?.created,
          params.row?.modified
        );
        return (
          <Box
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            title={length}
          >
            {length}
          </Box>
        );
      },
    },
    {
      field: 'inputs',
      headerName: t('Input'),
      flex: 2,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box width={'100%'}>
            {params.row?.inputs.map((input: any, i: number) => {
              return Object.keys(input.message).map(
                (key: string, j: number) => (
                  <Box
                    key={i + '_' + j}
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                  >
                    {key + ': ' + JSON.stringify(input.message[key])}
                  </Box>
                )
              );
            })}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Summary'),
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ViewIcon />}
          label={t('View')}
          onClick={() => navigate(`/admin/job/${params.id}`)}
        />,
      ],
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('No data services.'),
  };

  return (
    <Container disableGutters maxWidth="xl">
      <Stack spacing={2}>
        <DataGrid
          localeText={localeText}
          rows={rows}
          columns={columns}
          rowCount={rowCount}
          pageSizeOptions={[100]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'modified', sort: 'desc' }],
            },
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          pagination
          paginationMode="server"
          onPaginationModelChange={(params: any) => setPage(params.page)}
          sx={{ minHeight: 300 }}
        />
      </Stack>
    </Container>
  );
}

export default CompanyDataServicesList;
