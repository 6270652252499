import { DialogContent, IconButton, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import useSnackbarMessage from 'hooks/useSnackbarMessage';
import Dialog from 'components/common/Dialog/Dialog';
import DialogTitle from 'components/common/DialogTitle';
import EditCompanyDetails from 'components/App/Routes/Admin/Company/EditCompanyDetails';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';

const Component = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();
  const { view, companyId } = useParams();

  const onClose = () => navigate(`/admin/company/${companyId}`);

  return (
    <Dialog
      fullScreen
      open={view === 'edit'}
      onClose={onClose}
      transitionDuration={0}
    >
      <DialogTitle variant="h4">
        <Toolbar disableGutters>
          {t('Edit Company')}
          <div style={{ flex: 1 }} />
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </DialogTitle>
      <DialogContent>
        {companyId ? (
          <EditCompanyDetails
            id={companyId}
            onSuccess={() => {
              onClose();
              snackbarMessage.addMessage({
                message: t('Success'),
              });
            }}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default Component;
